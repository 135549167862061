import React from "react";

const CardNotFound = () => {
  return (
    <>
      <h2 className="my-3 my-lg-5">Carteira digital não encontrada.</h2>

      <p>
        Se sua documentação já foi aprovada, você possui uma filiação válida e
        sem pendências financeiras, fique tranquilo! Assim que sua carteira for
        emitida, sua carteira digital ficará disponível neste endereço.
      </p>
    </>
  );
};

export default CardNotFound;
