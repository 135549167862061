import React from "react";

const SuspendedCard = () => {
  return (
    <>
      <h2 className="my-3 my-lg-5">Perfil suspenso</h2>

      <p>
        Esta filiação encontra-se suspensa por descumprimento do Código de Ética
        do CITRG.
      </p>
    </>
  );
};

export default SuspendedCard;
